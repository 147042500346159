import * as React from "react"
import { Link } from "gatsby"
import logo from "../assets/images/logo.svg"
import "../assets/css/index.css"
import Layout from "../components/Layout"

export const Head = () => (
  <>
    <meta name="description" content="Enter HI9 STUDIOS" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale = 1.0, 
          maximum-scale=1.0"
    ></meta>
    <title>HI9 STUDIOS</title>
  </>
)

const IndexPage = () => (
    <Layout>
      <div class="index-page">
        <img class="index-logo" src={logo} alt="HI9 LOGO" />
        <Link to="/home" className="index-link">
          <button class="enter-button">ENTER</button>
        </Link>
      </div>
    </Layout>
)

export default IndexPage
